<template>
    <view-section-card title="내용">
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field v-model="form.name" label="이름" v-bind="{ ...attrs_input }" @input="emit" />
                    </v-col>
                    <v-col colse="3">
                        <v-text-field v-model="form.code" label="코드" v-bind="{ ...attrs_input }" @input="emit" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="form.desc" label="설명" v-bind="{ ...attrs_input }" @input="emit" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field v-model="form.index" label="순서" v-bind="{ ...attrs_input }" @input="emit" />
                    </v-col>

                    <!-- Collection -->
                    <template v-if="keyCode === 'Collections'">
                        <v-col cols="6">
                            <v-select v-model="form.value._category" :items="categoryItems" label="카테고리" v-bind="{ ...attrs_input }" clearable @input="emit" />
                        </v-col>
                    </template>

                    <!-- Color -->
                    <template v-if="keyCode === 'Colour'">
                        <v-col cols="6">
                            <v-card outlined class="fill-height">
                                <v-card-text style="position: relative">
                                    <span>색상</span>
                                    <v-card flat style="overflow: hidden">
                                        <v-color-picker v-model="form.value.color" hide-mode-switch mode="hexa" @update:color="changeColor" />
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-card outlined class="fill-height">
                                <v-card-text style="position: relative">
                                    <span class="d-inline-block white caption pa-1" style="position: absolute; top: 0; left: 8px; transform: translateY(-50%)">파일</span>
                                    <image-input v-model="form.value._img" :aspect-ratio="1 / 1" width="100%" flat />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { attrs_input, attrs_switch } from "@/assets/variables";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import ImageInput from "@/components/console/dumb/image-input.vue";

const initForm = (form = {}) => ({
    ...form,
    type: form?.type || "key",
    name: form?.name || null,
    code: form?.code || null,
    desc: form?.desc || null,
});

export default {
    components: {
        ViewSectionCard,
        ImageInput,
    },
    props: {
        value: { type: Object, default: initForm },
        keyCode: { type: String, default: "" },
    },
    data: () => ({
        attrs_input,
        attrs_switch,

        // all: false,
        form: initForm({ value: {} }),
    }),
    computed: {
        categoryItems() {
            return this.$store.state.categories
                .flatMap(function flatMap(category) {
                    let categories = [category];
                    if (0 < category.children.length) {
                        categories.push(...category.children.flatMap(flatMap));
                    }
                    return categories;
                })
                .map(({ name, _id }) => ({ text: name, value: _id }));
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = initForm(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        changeColor({ hexa } = {}) {
            this.form.value.color = hexa;
        },
    },
    watch: {
        value: {
            handler(newVal) {
                this.sync();
            },
            deep: true,
        }
    },
};
</script>

<style>
</style>