var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticStyle: {
      "overflow": "hidden",
      "max-height": "calc(100vh - 48px - 12px - 12px)"
    },
    attrs: {
      "column": ""
    }
  }, [_c('list-heading', {
    staticStyle: {
      "flex": "0 0 auto"
    },
    attrs: {
      "title": "대량등록"
    }
  }), _c('v-sheet', [_c('v-row', [_c('v-col', [_c('v-file-input', {
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1), _c('v-col', [_c('v-btn', _vm._b({
    on: {
      "click": _vm.processInfo
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("저장")])], 1)], 1)], 1), _c('v-sheet', [_c('v-row', [_c('v-col', [_c('v-file-input', {
    model: {
      value: _vm.fileImg,
      callback: function ($$v) {
        _vm.fileImg = $$v;
      },
      expression: "fileImg"
    }
  })], 1), _c('v-col', [_c('v-btn', _vm._b({
    on: {
      "click": _vm.processImageUpload
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }